import { Link } from "react-router-dom";
import { Social } from "../Social";
import { SocialItem } from "../Social/SocialItem";
import Instagram from "../../assets/images/social/instagram.svg"
import WhatsApp from "../../assets/images/social/whatsapp.svg"
import logo from "../../assets/images/logo-light.svg"


const Footer = () => {

    const footerData = [
        {
            title: 'Naviagtion',
            navs: [
                {
                    title: 'О нас',
                    link: '/#about'
                },
                {
                    title: 'Рапэ',
                    link: '/#rape'
                },
                {
                    title: 'Этапы',
                    link: '/#stages'
                },
                {
                    title: 'Запись',
                    link: '/#contact'
                },
            ]
        },
        {
            title: 'Security',
            navs: [
                {
                    title: 'Политика приватности',
                    link: '/privacy-policy'
                },
                {
                    title: 'Политика Cookie',
                    link: '/cookie-policy'
                },
            ]
        },
    ];

    const cols = footerData.map((col, index) => (
        <div className="ret-footer-ngrid-col" key={index}>
            {/* <div className="ret-footer-title">{col.title}</div> */}
            <div className="ret-footer-navs">
                {
                    col.navs.map((nav, index) => (
                        <div className="ret-footer-nav" key={index}>
                            <Link to={nav.link}>
                                <span>{nav.title}</span>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    ));

    const social = [
        {
            name: "Instagram",
            link: "https://www.instagram.com/mi_la_retritra?igsh=M3o1MWxpdHo2bTcx",
        },
        {
            name: "WhatsApp",
            link: "https://wa.me/77770557230"
        }

    ];

    const socialIcons = [Instagram, WhatsApp];

    const socialItems = social.map((item, index) => {
        const Icon = socialIcons[index];

        return (
            <SocialItem
                name={item.name}
                link={item.link}
                icon={<img src={Icon} alt={item.name} />}
                key={index}
            />
        )
    });

    return (
        <div className="ret-footer dark-bg">
            <div className="ret-footer-content">
                <div className="ret-footer-container">
                    <div className="ret-footer-row">
                        <div className="ret-footer-grid">
                            <a className="ret-footer-logo" href="/">
                                <img src={logo} alt="Logo" />
                            </a>
                            <div className="ret-footer-social">
                                <Social>
                                    {socialItems}
                                </Social>
                            </div>
                            <div className="ret-footer-ngrid">
                                {cols}
                            </div>
                        </div>
                    </div>
                    <div className="ret-footer-row">
                        <div className="ret-footer-copyright"><span>Copyright © 2023, Nelcosoft Sp. z o.o. All rights reserved.</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
