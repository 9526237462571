import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import "./firebase/firebaseConfig";
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/css/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <ToastContainer position="top-right" />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
