import { useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../context";
import WOW from "wow.js";
import Navbar from '../Navbar';
import Footer from '../Footer';

const Layout = ({ children }) => {
    const layoutRef = useRef();
    const Scrollbar = useRef();

    useEffect(() => {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
            mobile: false,
            live: true,
        });
        wow.init();
    }, []);

    const location = useLocation()

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            window.scrollTo({ top: 0, left: 0 })
        }
    }, [location,])

    return (
        <LayoutContext.Provider value={{ scrollbar: Scrollbar }}>
            <div className="ret-layout gray-bg" ref={layoutRef}>
                <Navbar />
                {children}
                <Footer />
            </div>
        </LayoutContext.Provider>
    );
};

export default Layout;
