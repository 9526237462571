import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import './assets/css/main.scss';
import Home from './pages/Home';
import NotFound from "./pages/NotFound";

function App() {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
