import React, { useEffect, useRef } from 'react';

const Script = () => {
  const scriptContainerRef = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn-ru.bitrix24.ru/b28074604/crm/form/loader_4.js?' + (Date.now() / 180000 | 0);
    script.setAttribute('data-b24-form', 'inline/4/tajcfn');
    script.setAttribute('data-skip-moving', 'true');

    const existingScript = scriptContainerRef.current.querySelector('script');
    if (!existingScript) {
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      if (existingScript) {
        scriptContainerRef.current.removeChild(existingScript);
      }
    };
  }, []);

  return <div ref={scriptContainerRef} />;
};

export default Script;
