import { useContext, useState, useRef } from "react";
import logo from "../../assets/images/logo.svg"
import { ButtonMenu } from '../Button/ButtonMenu';
import { Link } from "react-router-dom";
import { LayoutContext } from "../context";
import { useLocation } from "react-router-dom";

const Navbar = () => {
    
    const location = useLocation()
    const { scrollbar } = useContext(LayoutContext);
    const [isOpen, setIsOpen] = useState(false);
    const stripRef = useRef();

    const data = [
        {
            title: "О нас",
            link: "/#about"
        },
        {
            title: "Рапэ",
            link: "/#rape"
        },
        {
            title: "Этапы",
            link: "/#stages"
        },
        {
            title: "Запись",
            link: "/#contact"
        },
    ];

    const styleRounded = {
        padding: '6px 27px',
        border: '1px solid black',
        borderRadius: '25px',
        marginRight: '20px'
    }

    const style= {
        padding: 0,
        border: 0,
        borderRadius: 0,
        marginRight: 0
    }

    const closeMenu = () => {
        setIsOpen(current => !current);
        document.body.classList.remove("no-scroll");
        scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
    };

    const handleClick = () => {
        setIsOpen(current => !current);

        if (!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        }
    };

    const navs = data.map((item, index) => {

        const isActive = location.hash === item.link;
        const onClickHandler = isActive ? () => setIsOpen(false) : closeMenu;

        return (
            <Link className="ret-navbar-nav" key={index} to={item.link} onClick={onClickHandler}>
                <span>{item.title}</span>
            </Link>
        );
    });

    return (
        <nav className={`ret-navbar ${isOpen ? "-open" : ""}`}>
            <div className="ret-navbar-fill"></div>
            <div className="ret-navbar-strip" ref={stripRef}>
                <div className="ret-navbar-container">
                    <div className="ret-navbar-grid">
                        <div className="ret-navbar-grid-col -left">
                            <a className="ret-navbar-logo" href="/" aria-label="Home">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div className="ret-navbar-grid-col -center">
                            <div className="ret-navbar-navs">
                                {navs}
                            </div>
                        </div>
                        <div className="ret-navbar-grid-col -right">
                            <div className="ret-navbar-toggle">
                                <ButtonMenu onClick={handleClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
