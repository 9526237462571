import { useEffect, useRef } from "react";
import { magicShow } from "./transition";
import data from '../../data/benefits/data.json'

function Benefits() {

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [headerRef.current, contentRef.current]
        );
    }, []);

    const items = data.map((item, index) => (
        <div className="ret-benefits-item">
            <img src={require(`../../assets/images/icons/${item.icon}.svg`)} alt="" />
            <p>{item.title}</p>
        </div>
    ));

    return (
        <section id="benefits" className="ret-benefits" ref={triggerRef}>
            <div className="ret-benefits-content -cb">
                <div className="ret-benefits-header">
                    <h1 ref={headerRef}>Условия и цели</h1>
                </div>
                <div className="ret-benefits-reels">
                    <div className="ret-benefits-reel">
                        <div className="ret-benefits-reel-items" ref={contentRef}>
                            {items}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Benefits;