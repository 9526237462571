import React, { useState, useEffect, useRef } from 'react';
import { magicShow } from "./transition";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import {Helmet} from 'react-helmet';
import Script from '../../components/ContactForm';

function Contact() {

    const [canSend, setCanSend] = useState(true);
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [messageValue, setMessageValue] = useState('');

    const emailServer = process.env.REACT_APP_EMAIl_SERVER;
    const emailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE;
    const emailKey = process.env.REACT_APP_EMAIL_KEY;

    const sendEmail = (e) => {
        e.preventDefault();
        if (canSend) {
            setCanSend(false);
            emailjs.sendForm(emailServer, emailTemplate, e.target, emailKey)
                .then((result) => {
                    toast.success('Сообщение отправлено')
                    setNameValue('')
                    setEmailValue('')
                    setPhoneValue('')
                    setMessageValue('')
                    setCanSend(true);
                }, (error) => {
                    toast.error('Ошибка отправки сообщения, попробуйте позже');
                    setCanSend(true);
                });
        }
    };


    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const textRef = useRef();
    const formContainerRef = useRef();
    const formRef = useRef();
    const actionRef = useRef();

    useEffect(() => {
        magicShow(triggerRef.current, [headerRef.current, textRef.current, formContainerRef.current, formRef.current, actionRef.current]);
    }, []);

    return (
        <section id="contact" className='ret-contact' ref={triggerRef}>
            <div className="ret-contact-content">
                <div className='ret-contact-container'>
                    <h1 ref={headerRef}>Запись на ретрит</h1>
                    <div class="ret-contact-main">
                        <div className="text-content" ref={textRef}>
                            <p>Используйте форму для записи на наш ретрит.
                                Проверьте введенные данные, так как их мы
                                будем использовать во время бронирования
                                для вас отеля у наших партнеров.</p>
                            <p>Присоединитесь к нам на Ретрите и встретьте внутреннюю
                                силу, которая изменит ваш взгляд на жизнь. Научитесь
                                быть стойкими, несмотря на ветры перемен, и откройте
                                для себя гармонию в каждом моменте. Не упустите свой
                                шанс на личную трансформацию. Зарегистрируйтесь сейчас
                                и отправьтесь в увлекательное путешествие к себе!</p>
                        </div>
                        <div className="contact-form-wrap" >
           <Script ref={formContainerRef}/>
                        {/* <div id="bitrix-script-container"></div> */}
                            {/* <form ref={formRef} onSubmit={(e) => { sendEmail(e); }}>
                                <div className="row">
                                    <div className="form-grp">
                                        <input id="name" type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder="Имя и Фамилия" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-grp">
                                            <input id='email' type="email" name="from_email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder="Email адрес" required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-grp">
                                            <input id="phone" type="phone" name="from_phone" value={phoneValue} onChange={(e) => setPhoneValue(e.target.value)} placeholder="Номер телефона" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-grp">
                                    <textarea id="message" name="message" value={messageValue} onChange={(e) => setMessageValue(e.target.value)} placeholder="Сообщение" required></textarea>
                                </div>
                                <div className="submit-btn text-center" ref={actionRef}>
                                    <button type="submit" className="am-btn_cta" ref={actionRef}>
                                        Записаться
                                    </button>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
