import { useEffect, useRef } from "react";
import { magicShow } from "./transition";
import image from '../../assets/images/header.png'

function Header() {

        /* Animation effects */
        const triggerRef = useRef();
        const headerRef = useRef();
        const subheaderRef = useRef();
        const textRef = useRef();
        const imageRef = useRef();
    
        useEffect(() => {
            magicShow(
                triggerRef.current,
                [headerRef.current, subheaderRef.current, textRef.current, imageRef.current]
            );
        }, []);

    return (
        <header id="about" className="ret-header" ref={triggerRef}>
            <div className="ret-header-content">
                <div className="ret-header-container">
                    <div className="ret-header-main">
                        <div className="left-block">
                            <h1 ref={headerRef}>Ретрит - Женская Энергия</h1>
                            <h1 ref={subheaderRef}>Получи абсолютную энергию своего - Я</h1>
                            <p ref={textRef}>Уважаемые дамы, приглашаем вас на волшебное путешествие 
                            к освобождению вашей женской энергии на нашем эксклюзивном ретрите. 
                            Это не просто событие, это возможность встретить в себе мощь, 
                            страсть и силу, которые лежат в глубинах вашей души.Наши специально 
                            разработанные практики помогут вам освободить энергию, которая 
                            заложена в вас. Вы почувствуете себя сильнее, увереннее и свободнее.</p>
                        </div>
                        <div className="right-block">
                            <div className="image">
                                <img src={ image } alt="" ref={imageRef}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;