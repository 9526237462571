import { useEffect, useRef } from "react";
import { magicShow } from "./transition";
import circleImage from '../../assets/images/rape-1x.png'


function Additional() {

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const textRef = useRef();
    const imageRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [headerRef.current, textRef.current, imageRef.current]
        );
    }, []);

    return (
        <section id="rape" className="ret-additionl" ref={triggerRef}>
            <div className="ret-additional-content">
                <div className="ret-additional-container">
                    <div className="ret-additional-main">
                        <div className="image" >
                           <img src={ circleImage } alt="" ref={imageRef} />
                        </div>
                        <div className="text-container">
                        <h1 ref={headerRef}>Церемония рапэ</h1>
                        <p ref={textRef}>Рапэ - это инструмент шаманской священной медицины. 
                            Это легальная лекарственная смесь трав с мощным глубокими, 
                            целебными, очищающими и центрирующими свойствами, которая 
                            испокон использовалась коренными народами Южной Америки. 
                            Церемония помогает обрести состояние баланса и концентрации 
                            внимания «здесь и сейчас». Рапэ вызывает прилив бодрости,  
                            дает энергию и новые силы для осуществления желаемого. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Additional;