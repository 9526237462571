import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Additional from "../../sections/Additional";
import Conditions from "../../sections/Benefits";
import Contact from "../../sections/Contact";
import Stages from "../../sections/Stages";

function Home() {

    return (
        <Layout>
            <Header/>
            <Conditions/>
            <Additional/>
            <Stages/>
            <Contact/>
        </Layout>
    )
}

export default Home;