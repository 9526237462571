import { useEffect, useRef } from "react";
import { magicShow } from "./transition";

function Stages() {

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        magicShow(
            triggerRef.current,
            [headerRef.current, contentRef.current]
        );
    }, []);

    return (
        <section id="stages" className="ret-stages" ref={triggerRef}>
            <div className="ret-stages-content">
                <div className="ret-stages-container">
                    <div className="ret-benefits-header" ref={headerRef}>
                        <h1>Основные этапы</h1>
                    </div>
                    <div className="ret-stages-main" ref={contentRef}>
                        <div class="accordion accordion--radio">
                            <div class="tab">
                                <input type="radio" name="accordion-2" id="rd1" />
                                <label for="rd1" class="tab__label"><h2>Гвоздестояние</h2></label>
                                <div class="tab__content">
                                    <p>Практика гвоздестояния не только укрепляет ваше тело,
                                        но и развивает ваш ум, придавая ему спокойствие и четкость. Забудьте о
                                        шуме больших городов и погрузитесь в мир внутреннего покоя, современного
                                        комфорта и древней мудрости прямо здесь, среди пустыни.Присоединяйтесь к
                                        нам в Дубай и позвольте этому уникальному опыту пробудить в вас внутреннюю
                                        силу и гармонию</p>
                                </div>
                            </div>
                            <div class="tab">
                                <input type="radio" name="accordion-2" id="rd2" />
                                <label for="rd2" class="tab__label"><h2>Медитация и йога</h2></label>
                                <div class="tab__content">
                                    <p>Дорогие ценительницы загадочного и прекрасного! Мы приглашаем вас в
                                        уникальное путешествие по миру венерианской энергии, олицетворенной
                                        в изысканных пионах, под руководством нашего опытного астролога.
                                        Готовьтесь к проработке Венеры, которая принесет гармонию, красоту
                                        и любовь в каждый аспект вашей жизни.</p>
                                </div>
                            </div>
                            <div class="tab" style={{ paddingBottom: '16px' }}>
                                <input type="radio" name="accordion-2" id="rd3" />
                                <label for="rd3" class="tab__label"><h2>Связь с вселенной</h2></label>
                                <div class="tab__content">
                                    <p>Разбудите свою энергетическую сущность через медитации и практики, направленные на укрепление
                                        вашей связи с энергией Вселенной. Это поможет вам поднять свою вибрацию и обогатить ваш
                                        внутренний мир.</p>
                                    <p>Участие в ретрите принесет вам возможность личного роста и пробуждения. Вы станете более осознанными
                                        и открытыми к бесконечным возможностям, которые предлагает Вселенная.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stages;